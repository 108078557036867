import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RequestOptions, Request, RequestMethod} from '@angular/http';
import { environment } from '../../../environments/environment';
import { Ticket, fromServer } from './models/Ticket.model';
import { Category, fromServerC } from './models/Category.model';
import { Store } from '@ngrx/store';
import {
  URL_API_USER_TICKET,
  URL_API_TICKET_CATEGORY,
  URL_API_ADD_TICKET,
  URL_API_ADD_DOCUMENT,
  URL_API_TICKET_DETAILS,
  URL_API_ADD_FOLLOWUP,
  URL_API_UPDATE_TICKET, URL_API_SEARCH_TICKETS, URL_API_LAST_TICKET
} from '../../core/UrlAPI';
import { HttpParams } from "@angular/common/http";
import {Observable} from 'rxjs';
import {AppState} from '../../core/store/app.states';
import {timeout} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    protected http: HttpClient,
    private store: Store<AppState>
  ) { }

  getListTickets(): Observable<Ticket[]> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.get<Ticket[]>(URL_API_USER_TICKET, { headers: httpHeaders }) ;
  }

  getLastTicket(): Observable<any> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.get<any>(URL_API_LAST_TICKET, { headers: httpHeaders }) ;
  }

  getTicketDetails(id): Observable<Ticket> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.get<Ticket>(URL_API_TICKET_DETAILS + id, { headers: httpHeaders }) ;
  }

  getSearchResults(keyword): Observable<Ticket[]> {
    const token = localStorage.getItem(environment.headerToken);
    const params =  new HttpParams().set("keyword", keyword);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.get<Ticket[]>(URL_API_SEARCH_TICKETS, { headers: httpHeaders, params: params });
  }

  searchTickets() {
    return null;
  }

  getCategories(): Observable<Category[]> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.get<Category[]>(URL_API_TICKET_CATEGORY, { headers: httpHeaders }) ;
  }

  addTicket(input): Observable<any> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.post(URL_API_ADD_TICKET, input, { headers: httpHeaders }) ;
  }

  addFollowup(input): Observable<any> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.post(URL_API_ADD_FOLLOWUP + input['input']['tickets_id'], input, { headers: httpHeaders }) ;
  }

  addDocument(formData): Observable<any> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Session-Token': token
    });
    return this.http.post(URL_API_ADD_DOCUMENT, formData['formData'], { headers: httpHeaders }).pipe(timeout(5000)) ;
  }

  updateTicket(input): Observable<any> {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token': token
    });
    return this.http.put(URL_API_UPDATE_TICKET, input, { headers: httpHeaders }) ;
  }
}
