import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { URL_API_LOAD_USER } from '../../core/UrlAPI';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.loadUserInfo = function () {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_LOAD_USER, { headers: httpHeaders });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
