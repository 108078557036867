import {NgModule, Component, OnInit} from '@angular/core';
import { ToasterService, IToasterConfig, ToasterConfig } from 'angular2-toaster';
import {Subscription} from 'rxjs';
import {SidebarService} from './layout/sidebar/sidebar.service';
import {AuthService} from '../features/auth/auth.service';
import {HeaderService} from './layout/header/header.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  public expended = false ;
  public expendedSubscription: Subscription;

  constructor(protected headerService: HeaderService,
              private authService: AuthService,
              private translate: TranslateService) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }

  ngOnInit(): void {
      this.expendedSubscription = this.headerService.change.subscribe(

      value => {
        this.expended = value ;
      } );
  }
  isAuth() {
      return this.authService.isAuth() ;
  }
}
