import * as fromTickets from '../reducers/ticket.reducers';
import {AppState} from '../app.states';
import {createSelector} from '@ngrx/store';
export { selectTicketsIds, selectTicketsEntities, selectTickets, selectTotalTickets } from '../reducers/ticket.reducers';

// La première fonction amène vers le state tickets
export const selectTicketListState$ = (state: AppState) =>  state.tickets;

// Et à partir de celle-ci, on créer une autre fonction qui renverra data
/*export const selectMatieres$ = createSelector(selectMatiereListState$,(matieres) =>  matieres.data);*/

export const selectTicketListEntitiesConverted$ = createSelector(
  selectTicketListState$,
  fromTickets.selectTickets);

export const selectTicketsLoading$ =
  createSelector(selectTicketListState$, (tickets) =>  tickets.loading);

export const selectTicketsFilter$ =
  createSelector(selectTicketListState$, (tickets) =>  tickets.filter);

export const selectTicketsLoaded$ =
  createSelector(selectTicketListState$, (tickets) =>  tickets.loaded);

export const selectTicketsErrors$ =
  createSelector(selectTicketListState$, (tickets) => tickets.logs);

export const selectTicketById = (id: string) =>
  createSelector(selectTicketListState$, (tickets) => {
    // if (allMyFeatures) {
    //   return allMyFeatures.find(p => p.id === id);
    // } else {
    //   return null;
    // }
  });

