import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { TicketListModule } from "../../../core/store/actions/ticket.action";
import { Store } from "@ngrx/store";
import { AppState } from "../../../core/store/app.states";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // cloned headers, updated with the authorization.
    if (localStorage.getItem(environment.headerToken)) {
      const authReq = req.clone({
        headers: req.headers.set(
          "Session-Token",
          localStorage.getItem(environment.headerToken)
        ),
      });
      // send cloned request with header to the next handler with error handle.
      return next.handle(authReq).pipe(
        catchError((error, caught) => {
          this.handleError(error);
          return of(error);
        })
      );
    }
    return next.handle(req);
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleError(err: HttpErrorResponse): Observable<any> {
    console.log(err);
    if (
      err.status === 401 &&
      err.url !== "https://api-support.agena3000.com/Document"
    ) {
      console.log("401");
      this.router.navigate([`/login`]);
      this.store.dispatch(new TicketListModule.CancelLoading());
      return of(err.message);
    } else if (err.status === 404) {
      if (this.router.url === "/ticket") {
        this.translate.get("error.search").subscribe((res: string) => {
          this.toastr.error(res);
        });
      } else if (/\d/.test(this.router.url)) {
        this.translate.get("error.rights").subscribe((res: string) => {
          this.toastr.error(res);
        });
      }
    } else if (err.status === 413) {
      this.translate.get("error.file").subscribe((res: string) => {
        this.toastr.error(res);
      });
    } else if (err.status === 500 || err.status === 0) {
      this.translate.get("error.server").subscribe((res: string) => {
        this.toastr.error(res);
      });
    } else if (
      err.status === 401 &&
      err.url === "https://api-support.agena3000.com/Document"
    ) {
      this.translate.get("error.filetype").subscribe((res: string) => {
        this.toastr.error(res);
      });
    }
    this.store.dispatch(new TicketListModule.CancelLoading());
    return of(err.message);
  }
}
