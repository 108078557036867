import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import { SearchService } from './search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import { select, Store } from '@ngrx/store';
import {AppState, selectTicketState} from '../../store/app.states';
import {map, startWith} from 'rxjs/operators';
import {DatePipe, formatDate} from "@angular/common";
import { ActionsSubject } from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {TicketListModule} from "../../store/actions/ticket.action";

@Component({
  selector: 'app-layout-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public searchValue = '' ;
  searchKeyword = '';
  getTicketState: Observable<any>;
  users: Array<string> = [];
  filteredUsers: Observable<any>;
  expanded: boolean;
  formCtrl = new FormControl();
  pipe = new DatePipe('en-US');

  emit = {
    user: undefined,
    date: undefined,
    advancedSearch: undefined
  };

  constructor(
    public store: Store<AppState>,
    public searchService: SearchService,
    private route: ActivatedRoute,
    private actionsSubject: ActionsSubject,
    private translate: TranslateService,
    public router: Router,

  ) {
    this.getTicketState = this.store.pipe(select(selectTicketState));

    // Autocomplétion
    this.filteredUsers = this.formCtrl.valueChanges
      .pipe(
        startWith(''),
        map(user => user ? this.filterUsers(user) : this.users.slice())
      );
  }

  ngOnInit() {
    this.expanded = false;
    this.route.queryParams.subscribe((params) => {
      if (typeof params['search'] !== 'undefined') {
        this.searchValue = params['search'];
      }
    });

    // Récupération des utilisateurs
    this.getTicketState.subscribe(
      state => {
        Object.values(state.entities).map(ticket => {
          // let user = ticket['firstnameUser'] + ' ' + ticket['nameUser'];
          if(ticket['username'].trim().length > 0) {
            if(this.users.indexOf(ticket['username']) === -1) {
              this.users.push(ticket['username']);
            }
          }
        });
      }
    );

    this.actionsSubject.subscribe(data => {
      if(data.type === '[ticket] Clear Filter') {
        this.searchKeyword = '';
        this.emit = {
          user: undefined,
          date: undefined,
          advancedSearch: undefined
        };
      }
    });
  }

  // Emit de l'event
  onKey(value: string) {
    this.searchService.search(value);
  }

  advancedSearch(): void {
    if(this.emit.date !== null && this.emit.date !== undefined && this.emit.date !== '') {
      this.emit.date = this.pipe.transform(this.emit.date, 'yyyy-MM-dd');
    }

    if ((this.emit.user === '' || this.emit.user === undefined || this.emit.user === null)
      && (this.emit.date === '' || this.emit.date === undefined || this.emit.date === null)
      && (this.emit.advancedSearch === '' || this.emit.advancedSearch === undefined || this.emit.advancedSearch === null)) {
      this.emit.user = undefined;
      this.emit.date = undefined;
      this.emit.advancedSearch = undefined;

      this.store.dispatch(new TicketListModule.ClearFilterState());
      this.store.dispatch(new TicketListModule.LoadInitTickets());
    }

    this.searchService.search(this.emit);
    this.expanded = false;
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  onClickedOutside(e: Event) {
    if (e.target['className'] !== 'mat-option-text' && !e.target['className'].includes('calendar')) {
      this.expanded = false;
    }
  }

  // Autocomplétion
  private filterUsers(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(user => user.toLowerCase().includes(filterValue));
  }
}
