import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Ticket } from './models/Ticket.model';
import { Category } from './models/Category.model';
import { Store } from '@ngrx/store';
import { URL_API_USER_TICKET, URL_API_TICKET_CATEGORY, URL_API_ADD_TICKET, URL_API_ADD_DOCUMENT, URL_API_TICKET_DETAILS, URL_API_ADD_FOLLOWUP, URL_API_UPDATE_TICKET, URL_API_SEARCH_TICKETS, URL_API_LAST_TICKET } from '../../core/UrlAPI';
import { HttpParams } from "@angular/common/http";
import { timeout } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var TicketService = /** @class */ (function () {
    function TicketService(http, store) {
        this.http = http;
        this.store = store;
    }
    TicketService.prototype.getListTickets = function () {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_USER_TICKET, { headers: httpHeaders });
    };
    TicketService.prototype.getLastTicket = function () {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_LAST_TICKET, { headers: httpHeaders });
    };
    TicketService.prototype.getTicketDetails = function (id) {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_TICKET_DETAILS + id, { headers: httpHeaders });
    };
    TicketService.prototype.getSearchResults = function (keyword) {
        var token = localStorage.getItem(environment.headerToken);
        var params = new HttpParams().set("keyword", keyword);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_SEARCH_TICKETS, { headers: httpHeaders, params: params });
    };
    TicketService.prototype.searchTickets = function () {
        return null;
    };
    TicketService.prototype.getCategories = function () {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.get(URL_API_TICKET_CATEGORY, { headers: httpHeaders });
    };
    TicketService.prototype.addTicket = function (input) {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.post(URL_API_ADD_TICKET, input, { headers: httpHeaders });
    };
    TicketService.prototype.addFollowup = function (input) {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.post(URL_API_ADD_FOLLOWUP + input['input']['tickets_id'], input, { headers: httpHeaders });
    };
    TicketService.prototype.addDocument = function (formData) {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Session-Token': token
        });
        return this.http.post(URL_API_ADD_DOCUMENT, formData['formData'], { headers: httpHeaders }).pipe(timeout(5000));
    };
    TicketService.prototype.updateTicket = function (input) {
        var token = localStorage.getItem(environment.headerToken);
        var httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Session-Token': token
        });
        return this.http.put(URL_API_UPDATE_TICKET, input, { headers: httpHeaders });
    };
    TicketService.ngInjectableDef = i0.defineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.inject(i1.HttpClient), i0.inject(i2.Store)); }, token: TicketService, providedIn: "root" });
    return TicketService;
}());
export { TicketService };
