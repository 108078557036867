var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../features/auth/auth.service';
import { AuthActionTypes, LogIn, LogInSuccess, LogInFailure, } from '../actions/user.actions';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../features/user/user.service';
import { TicketListModule } from '../actions/ticket.action';
var ClearState = TicketListModule.ClearState;
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions, authService, userService, router, store) {
        var _this = this;
        this.actions = actions;
        this.authService = authService;
        this.userService = userService;
        this.router = router;
        this.store = store;
        this.LogIn = this.actions
            .ofType(AuthActionTypes.LOGIN)
            .map(function (action) { return action.payload; })
            .flatMap(function (payload) {
            return _this.authService.signIn(payload.login, payload.password)
                .map(function (data) {
                if (Object.prototype.hasOwnProperty.call(data, "disabled")) {
                    var attemptsArray = JSON.parse(localStorage.getItem("attempts"));
                    attemptsArray.forEach(function (element, index) {
                        if (element.username === payload.login && attemptsArray[index].enabled != true) {
                            attemptsArray[index].enabled = true;
                            _this.authService.enableAccount(payload.login).subscribe();
                        }
                    });
                    localStorage.setItem("attempts", JSON.stringify(attemptsArray));
                    return new LogInFailure({ error: Object.values(data)[2] });
                }
                return new LogInSuccess(data);
            })
                .catch(function (error) {
                return of(new LogInFailure({ error: error.error.message }));
            });
        });
        this.LogInSuccess = this.actions.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS), tap(function (user) {
            localStorage.removeItem("attempts");
            localStorage.setItem(environment.headerToken, user['data'].session_token);
            _this.store.dispatch(new ClearState());
        }), tap(function () {
            _this.router.navigate(['ticket']);
        }));
        this.loginRedirect$ = this.actions.pipe(ofType(AuthActionTypes.USER_LOGOUT), tap(function () {
            localStorage.removeItem(environment.headerToken);
            _this.router.navigate(['login']);
        }));
        this.LogInFailure = this.actions.pipe(ofType(AuthActionTypes.LOGIN_FAILURE), tap(function (payload) {
            return new LogInFailure(payload);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogIn", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogInSuccess", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AuthEffects.prototype, "loginRedirect$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogInFailure", void 0);
    return AuthEffects;
}());
export { AuthEffects };
