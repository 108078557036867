import { Action } from '@ngrx/store';


export enum AuthActionTypes {
  LOGIN         = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  USER          = '[User] Load User',
  USER_SUCCESS  = '[User] User success',
  USER_FAILURE  = '[User] User Failure',
  USER_LOGOUT   = '[User] User Logout'
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public data: any) {}
}


export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}


export class LoadUser implements Action {
  readonly type = AuthActionTypes.USER;
  constructor() {}
}


export class LoadUserSuccess implements Action {
  readonly type = AuthActionTypes.USER_SUCCESS;
  constructor(public payload: any) {}
}


export class LoadUserFailure implements Action {
  readonly type = AuthActionTypes.USER_FAILURE;
  constructor(public payload: any) {}
}

export class UserLogout implements Action {
  readonly type = AuthActionTypes.USER_LOGOUT;
}

export type All =
  | LogIn
  | LogInSuccess
  | LogInFailure
  | LoadUser
  | LoadUserSuccess
  | LoadUserFailure
  | UserLogout ;
