<header class="app-header navbar">
    
    <a class="navbar-toggler" (click)="changeExpended()">
        <i class="fas fa-bars" alt="Menu latéral" title="Menu latéral"></i>
    </a>
    <a class="navbar-brand" routerLink="/" alt="Bouton principal d'accès à la page d'accueil" title="Home">
        <img src="favicon.ico" class="navbar-icon">
        <span class="title-app"> Helpcenter </span>
    </a>
   
    <!-- <div class="search-panel">
        <form method="GET">
            <div class="generalSearch">
                <input class="inputSearch"  placeholder="Rechercher dans les tickets">
                <button class="btn-search" aria-label="Rechercher dans les messages" role="button">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </form>
    </div> -->
 
    <!-- <div id="current-page-title"><i class="material-icons">{{  menuRoutingService.getActiveRoute().icon }}</i>{{ menuRoutingService.getActiveRoute().label }}</div> -->
    <app-layout-search class="panel-search"></app-layout-search>
    <!-- <ul class="nav navbar-nav">
        <li class="nav-item">
            <a class="nav-link" href="#">Documentation Utilisateur</a>
        </li>
    </ul> -->
    <ul class="nav navbar-nav right">
        <li class="item-flag" value="en" (click)="translate.use('en')">
            <img src="../../../../assets/translation/en.png" >
        </li>
        <li class="item-flag" value="fr" (click)="translate.use('fr')">
            <img src="../../../../assets/translation/fr.png" >
        </li>

        <li class="nav-item">
            <a class="nav-link" target="_blank" href="../../../assets/Guide_HelpCenter_2019.pdf" title="Guide utilisateur" download="Guide utilisateur">
                <i class="fas fa-info-circle"></i>
            </a>
        </li>
        <li class="nav-item">
                <span class="nav-link" target="_blank"  title="Mon compte">
                    <i class="fas fa-user"></i>
                    {{username}}
                </span>
            </li>
        <li class="nav-item">
            <a class="nav-link" id="logout" (click)="signOut()" title="Déconnexion">
                <i class="fas fa-power-off"></i>
            </a>
        </li>
    </ul>

</header>