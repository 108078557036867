import { HttpErrorResponse } from '@angular/common/http';
import { Ticket } from '../../../features/ticket/models/Ticket.model';
import { Category } from "../../../features/ticket/models/Category.model";
import {Update} from "@ngrx/entity";

export namespace TicketListModule {

  export enum ActionTypes {
    LOAD_INIT_TICKETS           = '[ticketList] Load Init Tickets',
    SUCCESS_INIT_TICKETS        = '[ticketList] Success Init Tickets',
    LOAD_UPDATE_TICKETS         = '[ticketList] Load Update Tickets',
    SUCCESS_UPDATE_TICKETS      = '[ticketList] Success Update Tickets',
    LOAD_TICKET_DETAILS         = '[ticketList] Load Ticket Details',
    SUCCESS_TICKET_DETAILS      = '[ticketList] Success Ticket Details',
    SUCCESS_FILTER_TICKETS      = '[ticketList] Success Filter Tickets',
    LOAD_SEARCH_TICKETS         = '[ticketList] Load Search Tickets',
    SUCCESS_SEARCH_TICKETS      = '[ticketList] Success Search Tickets',
    LOAD_TICKET_CATEGORIES      = '[ticketList] Load Ticket Categories',
    SUCCESS_LOADING_CATEGORIES  = '[ticketList] Success Loading Categories',
    LOAD_UPLOAD_DOCUMENT        = '[ticketList] Load Upload Document',
    SUCCESS_UPLOAD_DOCUMENT     = '[ticketList] Success Upload Document',
    LOAD_CREATE_TICKET          = '[ticketList] Load Create Ticket',
    SUCCESS_CREATE_TICKET       = '[ticketList] Success Create Ticket',
    LOAD_ADD_FOLLOWUP           = '[ticketList] Load Add Followup',
    SUCCESS_ADD_FOLLOWUP        = '[ticketList] Success Add Followup',
    LOAD_UPDATE_TICKET          = '[ticketList] Load Update Ticket',
    SUCCESS_UPDATE_TICKET       = '[ticketList] Success Update Ticket',
    LOAD_UPDATE_STORE           = '[ticketList] Load Update Store',
    LOAD_LAST_TICKET            = '[ticketList] Load Last Ticket',
    SUCCESS_LAST_TICKET         = '[ticketList] Success Last Ticket',
    ERROR_LOAD_ACTION           = '[ticketList] Error Load Action',
    CLEAR_FOLLOWUP_STATE        = '[ticket] Clear Followup',
    CLEAR_FILTER_STATE          = '[ticket] Clear Filter',
    CLEAR_STATE                 = '[ticket] Clear',
    CANCEL_LOADING              = '[ticket] Cancel Loading'
  }

  export  class LoadInitTickets {
    readonly type = ActionTypes.LOAD_INIT_TICKETS;
  }

  export class SuccessInitTickets {
    readonly type = ActionTypes.SUCCESS_INIT_TICKETS;
    constructor( public payload: Ticket[]) {
    }
  }

  export  class LoadTicketDetails {
    readonly type = ActionTypes.LOAD_TICKET_DETAILS;
    constructor( public id: number) {}
  }
  export class SuccessTicketDetails {
    readonly type = ActionTypes.SUCCESS_TICKET_DETAILS;
    constructor( public payload: Ticket) {}
  }

  export class SuccessFilterTickets {
    readonly type = ActionTypes.SUCCESS_FILTER_TICKETS;
    constructor (public filter: any) {}
  }

  export class LoadSearchTickets {
    readonly type = ActionTypes.LOAD_SEARCH_TICKETS;
    constructor (public keyword: string) {}
  }

  export class SuccessSearchTickets {
    readonly type = ActionTypes.SUCCESS_SEARCH_TICKETS;
    constructor (public payload: Ticket[]) {}
  }

  export class LoadTicketCategories {
    readonly type = ActionTypes.LOAD_TICKET_CATEGORIES;
  }

  export class SuccessLoadingCategories {
    readonly type = ActionTypes.SUCCESS_LOADING_CATEGORIES;
    constructor(public payload: any[]) {};
  }

  export class LoadUploadDocument {
    readonly type = ActionTypes.LOAD_UPLOAD_DOCUMENT;
    constructor(public formData: any) {}
  }

  export class SuccessUploadDocument {
    readonly type = ActionTypes.SUCCESS_UPLOAD_DOCUMENT;
    constructor(public formData: any) {};
  }

  export class LoadCreateTicket {
    readonly type = ActionTypes.LOAD_CREATE_TICKET;
    constructor(public input: any) {}
  }

  export class SuccessCreateTicket {
    readonly type = ActionTypes.SUCCESS_CREATE_TICKET;
    constructor(public input: any) {}
  }

  export class LoadAddFollowup {
    readonly type = ActionTypes.LOAD_ADD_FOLLOWUP;
    constructor(public input: any) {}
  }

  export class SuccessAddFollowup {
    readonly type = ActionTypes.SUCCESS_ADD_FOLLOWUP;
    constructor(public input: any) {}
  }

  export class LoadUpdateTicket {
    readonly type = ActionTypes.LOAD_UPDATE_TICKET;
    constructor(public input: any) {}
  }

  export class SuccessUpdateTicket {
    readonly type = ActionTypes.SUCCESS_UPDATE_TICKET;
    constructor(public input: any) {}
  }

  export class LoadUpdateStore {
    readonly type = ActionTypes.LOAD_UPDATE_STORE;
    constructor(public input: { ticket: Update<Ticket> }) {}
  }

  export class LoadLastTicket {
    readonly type = ActionTypes.LOAD_LAST_TICKET;
  }

  export class SuccessLastTicket {
    readonly type = ActionTypes.SUCCESS_LAST_TICKET;
    constructor(public input: any) {}
  }

  export class ErrorLoadAction {
    readonly type = ActionTypes.ERROR_LOAD_ACTION;
    constructor(public payload: HttpErrorResponse) {}
  }

  export class ClearFilterState {
    readonly type = ActionTypes.CLEAR_FILTER_STATE;
  }

  export class ClearFollowupState {
    readonly type = ActionTypes.CLEAR_FOLLOWUP_STATE;
  }

  export class ClearState {
    readonly type = ActionTypes.CLEAR_STATE;
  }

  export class CancelLoading {
    readonly type = ActionTypes.CANCEL_LOADING;
  }

  export type Actions =
    LoadInitTickets          |
    SuccessInitTickets       |
    LoadTicketDetails        |
    SuccessTicketDetails     |
    LoadSearchTickets        |
    SuccessSearchTickets     |
    SuccessFilterTickets     |
    LoadTicketCategories     |
    SuccessLoadingCategories |
    LoadUploadDocument       |
    SuccessUploadDocument    |
    LoadCreateTicket         |
    SuccessCreateTicket      |
    LoadAddFollowup          |
    LoadUpdateTicket         |
    SuccessUpdateTicket      |
    LoadUpdateStore          |
    SuccessAddFollowup       |
    LoadLastTicket           |
    SuccessLastTicket        |
    ErrorLoadAction          |
    ClearFollowupState       |
    ClearFilterState         |
    ClearState               |
    CancelLoading
    ;
}
