var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createEntityAdapter } from '@ngrx/entity';
import { TicketListModule } from '../actions/ticket.action';
export function sortByDate(a, b) {
    return (a.dateModif < b.dateModif ? -1 : 1) * -1;
}
export var TicketListAdapter = createEntityAdapter({
    sortComparer: sortByDate
});
export var CategoriesListAdapter = createEntityAdapter({});
export var initialState = TicketListAdapter.getInitialState({
    loading: false,
    loaded: false,
    selectTicket: undefined,
    logs: undefined,
    filter: {
        status: 1,
        search: '',
        page: 1,
        product: 1,
        child: 1,
        group: 1,
        myTickets: false,
        user: '',
        date: '',
        advancedSearch: ''
    },
    categories: [],
    followup: [],
    lastTicket: undefined
});
export var selectTicketsIds = (_a = TicketListAdapter.getSelectors(), _a.selectIds), selectTicketsEntities = _a.selectEntities, selectTickets = _a.selectAll, selectTotalTickets = _a.selectTotal;
export function ticketsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TicketListModule.ActionTypes.LOAD_INIT_TICKETS:
            // Passe le loading a true
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_INIT_TICKETS:
            return __assign({}, TicketListAdapter.addAll(action['payload'], state), { loading: false, loaded: true });
        case TicketListModule.ActionTypes.LOAD_TICKET_DETAILS:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_TICKET_DETAILS:
            return __assign({}, TicketListAdapter.addOne(action['payload']['ticket'], state), { loading: false, loaded: true, followup: action['payload']['followup'] });
        case TicketListModule.ActionTypes.LOAD_SEARCH_TICKETS:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_SEARCH_TICKETS:
            return __assign({}, TicketListAdapter.addAll(action['payload']['tickets'], state), { loading: false, loaded: true });
        case TicketListModule.ActionTypes.SUCCESS_FILTER_TICKETS:
            return __assign({}, state, { filter: action['filter'] });
        case TicketListModule.ActionTypes.LOAD_TICKET_CATEGORIES:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_LOADING_CATEGORIES:
            return __assign({}, state, { loading: false, loaded: true, categories: action['payload'] });
        case TicketListModule.ActionTypes.LOAD_CREATE_TICKET:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_CREATE_TICKET:
            return __assign({}, TicketListAdapter.addOne(action['input'], state), { loading: false, loaded: true });
        case TicketListModule.ActionTypes.LOAD_ADD_FOLLOWUP:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_ADD_FOLLOWUP:
            return __assign({}, state, { followup: action['input'], loading: false, loaded: true });
        case TicketListModule.ActionTypes.LOAD_UPLOAD_DOCUMENT:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_UPLOAD_DOCUMENT:
            return __assign({}, state, { loading: false, loaded: true });
        case TicketListModule.ActionTypes.ERROR_LOAD_ACTION:
            return __assign({}, state, { logs: { type: 'ERROR', message: action.payload.message }, loading: false });
        case TicketListModule.ActionTypes.LOAD_UPDATE_TICKET:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_UPDATE_TICKET:
            return __assign({}, TicketListAdapter.updateOne(action['input'], state), { loading: false, loaded: true });
        case TicketListModule.ActionTypes.LOAD_LAST_TICKET:
            return __assign({}, state, { loading: true });
        case TicketListModule.ActionTypes.SUCCESS_LAST_TICKET:
            return __assign({}, state, { lastTicket: action['input'], loading: false, loaded: true });
        case TicketListModule.ActionTypes.LOAD_UPDATE_STORE:
            return __assign({}, TicketListAdapter.updateOne(action.input.ticket, state), { loading: false, loaded: true });
        case TicketListModule.ActionTypes.CLEAR_FOLLOWUP_STATE:
            return __assign({}, state, { followup: [] });
        case TicketListModule.ActionTypes.CLEAR_FILTER_STATE:
            return __assign({}, state, { filter: {
                    status: 1,
                    search: '',
                    page: 1,
                    product: 1,
                    child: 1,
                    group: 1,
                    myTickets: false,
                    user: '',
                    date: '',
                    advancedSearch: ''
                } });
        case TicketListModule.ActionTypes.CLEAR_STATE:
            return initialState;
        case TicketListModule.ActionTypes.CANCEL_LOADING:
            return __assign({}, state, { loading: false });
        default:
            return state;
    }
}
