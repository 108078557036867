import { Injectable } from '@angular/core';
import {Action, Store} from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';

import { AuthService } from '../../../features/auth/auth.service';
import {
  AuthActionTypes,
  LogIn, LogInSuccess, LogInFailure, LoadUser, LoadUserSuccess, LoadUserFailure,
} from '../actions/user.actions';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../features/user/user.service';
import {AppState} from '../app.states';
import {TicketListModule} from '../actions/ticket.action';
import ClearState = TicketListModule.ClearState;

@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  @Effect()
  LogIn: Observable<any> = this.actions
    .ofType(AuthActionTypes.LOGIN)
    .map((action: LogIn) => action.payload)
    .flatMap(payload => {
      return this.authService.signIn(payload.login, payload.password)
        .map((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "disabled")) {
            var attemptsArray = JSON.parse(localStorage.getItem("attempts"));
            attemptsArray.forEach((element, index) => {
              if(element.username === payload.login && attemptsArray[index].enabled != true) {
                attemptsArray[index].enabled = true;
                this.authService.enableAccount(payload.login).subscribe();
              }
          });
          localStorage.setItem("attempts", JSON.stringify(attemptsArray));
          return new LogInFailure({ error: Object.values(data)[2] });
          }
          return new LogInSuccess(data);
        })
        .catch((error) => {
          return of(new LogInFailure({ error: error.error.message }));
        });
    });

  @Effect({dispatch: false})
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user) => {
      localStorage.removeItem("attempts");
      localStorage.setItem(environment.headerToken, user['data'].session_token);
      this.store.dispatch(new ClearState()) ;
    }),
    tap(() => {
      this.router.navigate(['ticket']);
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions.pipe(
    ofType(AuthActionTypes.USER_LOGOUT),
    tap(() => {
      localStorage.removeItem(environment.headerToken) ;
      this.router.navigate(['login']) ;
    })
  );


  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((payload) => {
      return new LogInFailure(payload);
    }
  ));
}
