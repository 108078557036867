import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { fromServer } from './models/User.model';
import { URL_API_LOAD_USER } from '../../core/UrlAPI';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    protected http: HttpClient,
  ) { }

  loadUserInfo() {
    const token = localStorage.getItem(environment.headerToken) ;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Session-Token' : token
    });

    return this.http.get(URL_API_LOAD_USER, {headers: httpHeaders}) ;
  }
}
