import { Component, OnInit } from '@angular/core';
import {IToasterConfig, ToasterConfig, ToasterService} from 'angular2-toaster';
import {Subscription} from 'rxjs';
import {HeaderService} from '../header/header.service';
import {AuthService} from '../../../features/auth/auth.service';

@Component({
  selector: 'app-layout-component',
  templateUrl: './layout-component.component.html',
  styleUrls: ['./layout-component.component.scss']
})
export class LayoutComponentComponent implements OnInit {

  public toasterService: ToasterService;
  public expended = false ;
  public expendedSubscription: Subscription;
  public appToasterConfig: IToasterConfig = new ToasterConfig({
    animation: 'fade', newestOnTop: true
  });

  constructor(toasterService: ToasterService, protected headerService: HeaderService, private authService: AuthService) {
    this.toasterService = toasterService;
  }
  

  ngOnInit(): void {
    this.expendedSubscription = this.headerService.change.subscribe(

      value => {
        this.expended = value ;
      } );
  }
}
