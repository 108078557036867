import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../features/auth/auth.service';
import {Toast, ToasterService} from 'angular2-toaster';
import { MenuRoutingService } from '../routing/menuRouting.service';
import {environment} from '../../../../environments/environment';
import {HeaderService} from './header.service';
import {Store} from '@ngrx/store';
import {AuthModule} from '../../../features/auth/auth.module';
import {UserLogout} from '../../store/actions/user.actions';
import {AppState} from '../../store/app.states';
import {TranslateService} from "@ngx-translate/core";
import {TicketListModule} from "../../store/actions/ticket.action";
import ClearState = TicketListModule.ClearState;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  authStatus: boolean;
  expended = false;
  auth = [] ;
  username: string ;
  signedOut: Toast = {
    type: 'error',
    title: 'Vous êtes déconnecté',
  };

  constructor(private authService: AuthService,
              private router: Router,
              private toasterService: ToasterService,
              private headerService: HeaderService,
              private store: Store<AppState>,
              public translate: TranslateService
  ) {
    this.auth = JSON.parse(localStorage.getItem('auth')) ;
    this.username = this.auth['user']['session']['glpiname'] ;
  }

  ngOnInit() {
    this.authStatus = this.authService.isAuth();
  }

  signOut() {
    // this.store.dispatch(new UserLogout()) ;
    this.store.dispatch(new UserLogout()) ;
  }

  changeExpended(): void {
    this.expended = (!this.expended) ;
    this.headerService.expendSidebar(this.expended) ;
  }
}
