import {User} from '../../../features/user/models/User.model';
import {All, AuthActionTypes} from '../actions/user.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';

export interface State {
  // is a user authenticated?
  isAuthenticated: boolean;
  loading: boolean;
  // if authenticated, there should be a user object
  user: User | null;
  userInfos: Array<string> | null;
  userGroups: Array<string> | null;
  userEntities: Array<string> | null;
  token: string | null;
  // error message
  errorMessage: string | null;
}

export const initialState: State = {
  isAuthenticated: false,
  loading: false,
  user: null,
  userInfos: null,
  userGroups: null,
  userEntities: null,
  token: null,
  errorMessage: null
};

export const UserAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: false
});

export const {
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectUser,
  selectTotal: selectTotalUser
} = UserAdapter.getSelectors();

export function userReducer(state = initialState, action: All): State {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: action.data.user,
        userInfos: action.data.user_infos,
        userGroups: action.data.user_groups,
        userEntities: action.data.user_entities,
        token: action.data.session_token,
        errorMessage: null,
        loading: false,
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.error,
        loading: false,
      };
    }
    case AuthActionTypes.USER_SUCCESS: {
      return {
        ...state,
        user: {
          id: action.payload.id,
          username: action.payload.username,
          name: action.payload.name,
          firstname: action.payload.firstname,
        },
        loading: false,
      };
    }
    case AuthActionTypes.USER_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
