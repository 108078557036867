<div id="sidebar" [ngClass]="{'expended' : expended}">
    <div class="add-ticket">
        <button class="btn btn-pill btn-block btn-success" (click)="addTicket()">
            <i class="fas fa-plus"></i>
            <span class="label">{{ 'ticket.add.ticketCreation' | translate }}</span>

        </button>
    </div>
    <ul class="sidebar-nav">

        <li class="nav-item">

            <a class="nav-link" (click)="changeStatus(1)" [ngClass]="{'active' : currentStatus === 1 }" data-toggle="Tous les tickets">
                <i class="far fa-credit-card"></i>
                <span class="label">{{ 'app.sidebar.allTickets' | translate }}</span>
            </a>

        </li>

        <li class="nav-item">
            <a  class="nav-link" (click)="changeStatus(2)" [ngClass]="{'active' : currentStatus === 2 }">
                <i class="far fa-clock"></i>
                <span class="label">{{ 'app.sidebar.nonClos' | translate }}</span>
            </a>
        </li>

        <li class="nav-item">
            <a  class="nav-link" (click)="changeStatus(3)" [ngClass]="{'active' : currentStatus === 3 }">
                <i class="fas fa-check-double"></i>
                <span class="label">{{ 'app.sidebar.clos' | translate }}</span>
            </a>
        </li>

    </ul>

    <ul class="sidebar-nav mobile">
        <li class="nav-item">
            <a class="nav-link" target="_blank" href="../../../assets/Guide_SVP_user-1.pdf" title="Guide utilisateur" download="Guide utilisateur">
                <i class="fas fa-info-circle"></i>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" target="_blank" title="Mon compte" download="Guide utilisateur">
                <i class="fas fa-user"></i>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="logout"  title="Déconnexion">
                <i class="fas fa-power-off"></i>
            </a>
        </li>
    </ul>
</div>
