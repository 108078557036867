import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Ticket} from '../../../features/ticket/models/Ticket.model';
import {TicketListModule} from '../actions/ticket.action';
import {Category} from "../../../features/ticket/models/Category.model";

export interface TicketListStateEntity extends EntityState<Ticket> {
  loading: boolean;
  loaded: boolean;
  selectTicket: Ticket;
  filter: Object;
  categories: Array<string>;
  followup: Array<string>;
  lastTicket: number;
  logs: {
    type: string;
    message: string;
  };
}

export function sortByDate(a: Ticket, b: Ticket): number {
  return (a.dateModif < b.dateModif ? -1 : 1) * -1;
}

export const TicketListAdapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>({
    sortComparer: sortByDate
});

export const CategoriesListAdapter: EntityAdapter<Category> = createEntityAdapter<Category>({});

export const initialState: TicketListStateEntity = TicketListAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectTicket: undefined,
  logs: undefined,
  filter: {
    status: 1,
    search: '',
    page: 1,
    product: 1,
    child: 1,
    group: 1,
    myTickets: false,
    user: '',
    date: '',
    advancedSearch: ''
  },
  categories: [],
  followup: [],
  lastTicket: undefined
});

export const {
  selectIds: selectTicketsIds,
  selectEntities: selectTicketsEntities,
  selectAll: selectTickets,
  selectTotal: selectTotalTickets
} = TicketListAdapter.getSelectors();

export function ticketsReducer(
  state = initialState,
  action: TicketListModule.Actions
): TicketListStateEntity {
  switch (action.type) {
    case TicketListModule.ActionTypes.LOAD_INIT_TICKETS:
      // Passe le loading a true
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_INIT_TICKETS:
      return {
        ...TicketListAdapter.addAll(action['payload'], state),
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.LOAD_TICKET_DETAILS:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_TICKET_DETAILS:
      return {
        ...TicketListAdapter.addOne(action['payload']['ticket'], state),
        loading: false,
        loaded: true,
        followup: action['payload']['followup']
      };

    case TicketListModule.ActionTypes.LOAD_SEARCH_TICKETS:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_SEARCH_TICKETS:
      return {
        ...TicketListAdapter.addAll(action['payload']['tickets'], state),
        loading: false,
        loaded: true,
      }

    case TicketListModule.ActionTypes.SUCCESS_FILTER_TICKETS:
      return {
        ... state,
        filter: action['filter']
      }

    case TicketListModule.ActionTypes.LOAD_TICKET_CATEGORIES:
    return {
      ...state,
      loading: true
    };

    case TicketListModule.ActionTypes.SUCCESS_LOADING_CATEGORIES:
      return {
        ...state,
        loading: false,
        loaded: true,
        categories: action['payload']
      };

    case TicketListModule.ActionTypes.LOAD_CREATE_TICKET:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_CREATE_TICKET:
      return {
        ...TicketListAdapter.addOne(action['input'], state),
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.LOAD_ADD_FOLLOWUP:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_ADD_FOLLOWUP:
      return {
        ...state,
        followup: action['input'],
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.LOAD_UPLOAD_DOCUMENT:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_UPLOAD_DOCUMENT:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.ERROR_LOAD_ACTION:
      return {
        ...state,
        logs: { type: 'ERROR', message: action.payload.message },
        loading: false
      };

    case TicketListModule.ActionTypes.LOAD_UPDATE_TICKET:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_UPDATE_TICKET:
      return {
        ...TicketListAdapter.updateOne(action['input'], state),
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.LOAD_LAST_TICKET:
      return {
        ...state,
        loading: true
      };

    case TicketListModule.ActionTypes.SUCCESS_LAST_TICKET:
      return {
        ...state,
        lastTicket: action['input'],
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.LOAD_UPDATE_STORE:
      return {
        ...TicketListAdapter.updateOne(action.input.ticket, state),
        loading: false,
        loaded: true,
      };

    case TicketListModule.ActionTypes.CLEAR_FOLLOWUP_STATE:
      return {
        ... state,
        followup: []
      };

    case TicketListModule.ActionTypes.CLEAR_FILTER_STATE:
      return {
        ... state,
        filter: {
          status: 1,
          search: '',
          page: 1,
          product: 1,
          child: 1,
          group: 1,
          myTickets: false,
          user: '',
          date: '',
          advancedSearch: ''
        }
      }

    case TicketListModule.ActionTypes.CLEAR_STATE:
      return initialState;

    case TicketListModule.ActionTypes.CANCEL_LOADING:
      return {
        ... state,
        loading: false
      }

    default:
      return state;
  }
}
