var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { AuthActionTypes } from '../actions/user.actions';
import { createEntityAdapter } from '@ngrx/entity';
export var initialState = {
    isAuthenticated: false,
    loading: false,
    user: null,
    userInfos: null,
    userGroups: null,
    userEntities: null,
    token: null,
    errorMessage: null
};
export var UserAdapter = createEntityAdapter({
    sortComparer: false
});
export var selectUserIds = (_a = UserAdapter.getSelectors(), _a.selectIds), selectUserEntities = _a.selectEntities, selectUser = _a.selectAll, selectTotalUser = _a.selectTotal;
export function userReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.LOGIN: {
            return __assign({}, state, { loading: true });
        }
        case AuthActionTypes.LOGIN_SUCCESS: {
            return __assign({}, state, { isAuthenticated: true, user: action.data.user, userInfos: action.data.user_infos, userGroups: action.data.user_groups, userEntities: action.data.user_entities, token: action.data.session_token, errorMessage: null, loading: false });
        }
        case AuthActionTypes.LOGIN_FAILURE: {
            return __assign({}, state, { errorMessage: action.payload.error, loading: false });
        }
        case AuthActionTypes.USER_SUCCESS: {
            return __assign({}, state, { user: {
                    id: action.payload.id,
                    username: action.payload.username,
                    name: action.payload.name,
                    firstname: action.payload.firstname,
                }, loading: false });
        }
        case AuthActionTypes.USER_LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
