import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class HeaderService {

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  expendSidebar(value) {
    this.change.emit(value);
  }
}
