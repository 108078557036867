import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../features/auth/auth-guard.service';
import {LayoutComponentComponent} from './layout/layout-component/layout-component.component';
import {LayoutLoginComponent} from './layout/layout-login/layout-login.component';
@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        component: LayoutComponentComponent,
        children: [
          {
            path: '',
            redirectTo: 'ticket',
            pathMatch: 'full'
          },
          {
            path: 'ticket',
            loadChildren: './../features/ticket/ticket.module#TicketModule',
            canActivate: [AuthGuard]
          },
        ],
      },
      {
        path: 'login',
        component: LayoutLoginComponent,
        children: [
          { path: '',
            loadChildren: './../features/auth/auth.module#AuthModule'
          }
        ],
      },
      {
        path: '**',
        redirectTo: 'ticket',
        pathMatch: 'full'
      }
    ])
  ],
  exports: [ RouterModule ] // re-export the module declarations
})
export class AppRoutingModule { }
