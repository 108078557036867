import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap';
import {of} from 'rxjs/index';
import { TicketService } from '../../../features/ticket/ticket.service';
import { TicketListModule } from '../actions/ticket.action';
import {AuthActionTypes, LogIn, LogInFailure, LogInSuccess, UserLogout} from '../actions/user.actions';
import {environment} from '../../../../environments/environment';
import SuccessCreateTicket = TicketListModule.SuccessCreateTicket;
import { Router } from '@angular/router';
import LoadCreateTicket = TicketListModule.LoadCreateTicket;
import LoadUploadDocument = TicketListModule.LoadUploadDocument;
import SuccessUploadDocument = TicketListModule.SuccessUploadDocument;
import LoadAddFollowup = TicketListModule.LoadAddFollowup;
import SuccessAddFollowup = TicketListModule.SuccessAddFollowup;
import LoadUpdateTicket = TicketListModule.LoadUpdateTicket;
import SuccessUpdateTicket = TicketListModule.SuccessUpdateTicket;
import {Store} from '@ngrx/store';
import {AppState} from '../app.states';
import SuccessLastTicket = TicketListModule.SuccessLastTicket;


@Injectable()
export class TicketListEffects {

  constructor(
    private  ticketService: TicketService,
    private  actions$: Actions,
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  // Ecoute les actions passées dans le store
  @Effect() LoadTickets$: Observable<TicketListModule.Actions> = this.actions$
    .pipe(
      ofType(TicketListModule.ActionTypes.LOAD_INIT_TICKETS),
      switchMap(action => this.ticketService.getListTickets()),
      map(tickets => new TicketListModule.SuccessInitTickets(tickets)),
      catchError((err) => of(new TicketListModule.ErrorLoadAction(err)))
    );

  @Effect() LoadTicket$: Observable<TicketListModule.Actions> = this.actions$
    .pipe(
      ofType(TicketListModule.ActionTypes.LOAD_TICKET_DETAILS),
      switchMap(action => this.ticketService.getTicketDetails(action['id'])),
      map(ticket => new TicketListModule.SuccessTicketDetails(ticket)),
      catchError((err) => of(new TicketListModule.ErrorLoadAction(err)))
    );

  @Effect() LoadTicketSearch$: Observable<TicketListModule.Actions> = this.actions$
    .pipe(
      ofType(TicketListModule.ActionTypes.LOAD_SEARCH_TICKETS),
      exhaustMap(action => this.ticketService.getSearchResults(action['keyword'])),
      map(tickets => new TicketListModule.SuccessSearchTickets(tickets)),
      catchError((err) => of(new TicketListModule.ErrorLoadAction(err)))
    );

  @Effect() LoadTicketCategories$: Observable<TicketListModule.Actions> = this.actions$
    .pipe(
      ofType(TicketListModule.ActionTypes.LOAD_TICKET_CATEGORIES),
      switchMap(action => this.ticketService.getCategories()),
      map(categories => new TicketListModule.SuccessLoadingCategories(categories)),
      catchError((err) => of(new TicketListModule.ErrorLoadAction(err)))
    );

  @Effect()
  LoadCreateTicket$: Observable<any> = this.actions$
    .ofType(TicketListModule.ActionTypes.LOAD_CREATE_TICKET)
    .map((action: LoadCreateTicket) => action)
    .switchMap(payload => {
      return this.ticketService.addTicket(payload)
        .map((data) => {
          return new SuccessCreateTicket(data);
        });
      // .catch((error) => {
      //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
      // });
    });

  @Effect({dispatch: false})
  SuccessCreateTicket$: Observable<any> = this.actions$.pipe(
    ofType(TicketListModule.ActionTypes.SUCCESS_CREATE_TICKET),
    tap((data) => {
      new TicketListModule.SuccessInitTickets(data['input']);
      this.router.navigate(['ticket']);
    })
  );

  @Effect()
  LoadAddFollowup$: Observable<any> = this.actions$
    .ofType(TicketListModule.ActionTypes.LOAD_ADD_FOLLOWUP)
    .map((action: LoadAddFollowup) => action)
    .switchMap(payload => {
      return this.ticketService.addFollowup(payload)
        .map((data) => {
          return new SuccessAddFollowup(data);
        });
      // .catch((error) => {
      //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
      // });
    });

  @Effect({dispatch: false})
  SuccessAddFollowup$: Observable<any> = this.actions$.pipe(
    ofType(TicketListModule.ActionTypes.SUCCESS_ADD_FOLLOWUP),
    tap((data) => {
      new TicketListModule.SuccessAddFollowup(data['input']);
    })
  );

  @Effect()
  LoadUpdateTicket$: Observable<any> = this.actions$
    .ofType(TicketListModule.ActionTypes.LOAD_UPDATE_TICKET)
    .map((action: LoadUpdateTicket) => action)
    .switchMap(payload => {
      return this.ticketService.updateTicket(payload)
        .map((data) => {
          return new SuccessUpdateTicket(data);
        });
      // .catch((error) => {
      //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
      // });
    });

  @Effect({dispatch: false})
  SuccessUpdateTicket$: Observable<any> = this.actions$.pipe(
    ofType(TicketListModule.ActionTypes.SUCCESS_UPDATE_TICKET),
    tap((data) => {
      new TicketListModule.SuccessUpdateTicket(data);
    })
  );

  @Effect()
  LoadUploadDocument$: Observable<any> = this.actions$
    .ofType(TicketListModule.ActionTypes.LOAD_UPLOAD_DOCUMENT)
    .map((action: LoadUploadDocument) => action)
    .flatMap(payload => {
      return this.ticketService.addDocument(payload)
        .map((data) => {
          return new SuccessUploadDocument(data);
        });
    });

  @Effect({dispatch: false})
  SuccessUploadDocument$: Observable<any> = this.actions$.pipe(
    ofType(TicketListModule.ActionTypes.SUCCESS_UPLOAD_DOCUMENT),
    tap((data) => {
      new TicketListModule.SuccessUploadDocument(data['formData']);
    })
  );

  @Effect()
  LoadLastTicket$: Observable<any> = this.actions$
    .ofType(TicketListModule.ActionTypes.LOAD_LAST_TICKET)
    .map((action: LoadUpdateTicket) => action)
    .switchMap(payload => {
      return this.ticketService.getLastTicket()
        .map((data) => {
          return new SuccessLastTicket(data);
        });
    });

  @Effect({dispatch: false})
  SuccessLastTicket$: Observable<any> = this.actions$.pipe(
    ofType(TicketListModule.ActionTypes.SUCCESS_LAST_TICKET),
    tap((data) => {
      new TicketListModule.SuccessLastTicket(data);
    })
  );
}
