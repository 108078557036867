import { environment } from "../../environments/environment";

// API LOGIN
export const URL_API_LOGIN = environment.adminUrl + "/api_login";

// API LOGOUT
export const URL_API_LOGOUT = environment.adminUrl + "/api_logout";

// API RESET PASSWORD
export const URL_API_SEND_EMAIL_RESET =
  environment.adminUrl + "/interne/users/resetPassword";

// API RESET PASSWORD
export const URL_API_RESET =
  environment.adminUrl + "/interne/users/changePassword";

// API GET USERNAME
export const URL_API_GET_USERNAME =
  environment.adminUrl + "/interne/users/getUsername";

// API TICKET
export const URL_API_INDEX_TICKET = environment.adminUrl + "/api/tickets/";

// API USER TICKETS
export const URL_API_USER_TICKET =
  environment.adminUrl + "/api/tickets/user_ticket";

// API LAST TICKET
export const URL_API_LAST_TICKET =
  environment.adminUrl + "/api/tickets/lastTicket";

// API USER TICKET
export const URL_API_TICKET_DETAILS =
  environment.adminUrl + "/api/tickets/ticketFollowup/";

// API SEARCH TICKETS
export const URL_API_SEARCH_TICKETS =
  environment.adminUrl + "/api/tickets/search";

// API TICKET CATEGORY
export const URL_API_TICKET_CATEGORY =
  environment.adminUrl + "/api/tickets/categories";

// API TICKET DOCUMENT
export const URL_API_TICKET_DOCUMENT =
  environment.adminUrl + "/api/documents/get/";

// API ADD TICKET
export const URL_API_ADD_TICKET = environment.adminUrl + "/api/tickets/add";

// API ADD FOLLOWUP
export const URL_API_ADD_FOLLOWUP =
  environment.adminUrl + "/api/tickets/addFollowup/";

// API UPDATE TICKET
export const URL_API_UPDATE_TICKET =
  environment.adminUrl + "/api/tickets/update";

// API ADD DOCUMENT
export const URL_API_ADD_DOCUMENT = environment.apiUrl + "/Document";

// API CLOSED USER TICKETS
export const URL_API_CLOSE_TICKET =
  environment.adminUrl + "/api/tickets/user_ticket/close";

// API OPENED USER TICKETS
export const URL_API_OPEN_TICKET =
  environment.adminUrl + "/api/tickets/user_ticket/open";

// API USER
export const URL_API_LOAD_USER =
  environment.adminUrl + "/api/users/loadInfoUser";

// API DISABLE/ENABLE USER
export const URL_API_DISABLE_ENABLE_USER =
environment.adminUrl + "/api_disable";
