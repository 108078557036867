import { Component, OnInit } from '@angular/core';
import {SidebarService} from './sidebar.service';
import {select, Store} from '@ngrx/store';
import {selectTicketsFilter$} from '../../store/selectors/ticket.selector';
import {AppState} from '../../store/app.states';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {HeaderService} from '../header/header.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  filterLoaded$: Observable<any>;
  currentStatus: number ;
  public expended = false ;
  public expendedSubscription: Subscription;
  constructor(
    private store: Store<AppState>,
    public sidebarService: SidebarService,
    public headerService: HeaderService,
    private router: Router,
  ) {
    this.filterLoaded$ = store.pipe(select(selectTicketsFilter$));
  }

  ngOnInit() {
    this.filterLoaded$.subscribe(
      data => this.currentStatus = data.status
    );
    this.expendedSubscription = this.headerService.change.subscribe(
      value => {
        this.expended = value ;
      } );
  }

  changeStatus(value): void {
    this.currentStatus = value ;
    this.sidebarService.changeStatus(value);
    if (this.router.url !== '/ticket') {
      this.router.navigate(['ticket']) ;
    }
  }
  addTicket() {
    this.router.navigate(['ticket/new']) ;
  }

}
