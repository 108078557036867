import * as auth from './reducers/auth.reducers';
import {TicketListStateEntity, ticketsReducer} from './reducers/ticket.reducers';
import {AuthEffects} from './effects/auth.effects';
import {TicketListEffects} from './effects/ticket.effects';
import {InjectionToken} from '@angular/core';
import {ActionReducerMap} from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

export interface AppState {
  authState: auth.State;
  tickets: TicketListStateEntity;
}

export const reducers = {
  auth: auth.userReducer,
  tickets: ticketsReducer
};

export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectTicketState = createFeatureSelector<AppState>('tickets');

// Nécéssaire pour l'AOT
export function getReducers() {
  return reducers;
}

// Nécéssaire pour l'AOT
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');
export const effects = [TicketListEffects, AuthEffects];
