<div class="global-search">
  <i class="left material-icons">search</i>
  <input type="text" name="global_search" placeholder="{{ 'ticket.index.search' | translate }}" #box (keyup)="onKey(box.value)" value="{{searchValue}}"
         [(ngModel)]="searchKeyword">
  <i class="right material-icons" (click)="toggleExpand()" *ngIf="router.url === '/ticket'" #toggle>arrow_drop_down</i>
</div>

<div class="advanced-search" *ngIf="expanded === true" #advanced (clickOutside)="onClickedOutside($event)"
     [exclude]="'i'">
    <form class="example-form" (ngSubmit)="advancedSearch()">
        <mat-form-field>
            <input matInput placeholder="{{ 'ticket.index.userSearch' | translate }}" aria-label="User" [matAutocomplete]="auto"
                   [formControl]="formCtrl" [(ngModel)]="emit.user" name="user">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                    {{ user }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <br>

        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="{{ 'ticket.index.dateSearch' | translate }}"
                   [(ngModel)]="emit.date" name="date" (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <br>

        <mat-form-field>
            <input matInput placeholder="{{ 'ticket.index.followupSearch' | translate }}"  [(ngModel)]="emit.advancedSearch" name="advancedSearch">
        </mat-form-field>
        <br>
        <button mat-button type="submit">{{ 'ticket.index.search2' | translate }}</button>
    </form>
</div>

