var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap';
import { of } from 'rxjs/index';
import { TicketService } from '../../../features/ticket/ticket.service';
import { TicketListModule } from '../actions/ticket.action';
var SuccessCreateTicket = TicketListModule.SuccessCreateTicket;
import { Router } from '@angular/router';
var SuccessUploadDocument = TicketListModule.SuccessUploadDocument;
var SuccessAddFollowup = TicketListModule.SuccessAddFollowup;
var SuccessUpdateTicket = TicketListModule.SuccessUpdateTicket;
import { Store } from '@ngrx/store';
var SuccessLastTicket = TicketListModule.SuccessLastTicket;
var TicketListEffects = /** @class */ (function () {
    function TicketListEffects(ticketService, actions$, router, store) {
        var _this = this;
        this.ticketService = ticketService;
        this.actions$ = actions$;
        this.router = router;
        this.store = store;
        // Ecoute les actions passées dans le store
        this.LoadTickets$ = this.actions$
            .pipe(ofType(TicketListModule.ActionTypes.LOAD_INIT_TICKETS), switchMap(function (action) { return _this.ticketService.getListTickets(); }), map(function (tickets) { return new TicketListModule.SuccessInitTickets(tickets); }), catchError(function (err) { return of(new TicketListModule.ErrorLoadAction(err)); }));
        this.LoadTicket$ = this.actions$
            .pipe(ofType(TicketListModule.ActionTypes.LOAD_TICKET_DETAILS), switchMap(function (action) { return _this.ticketService.getTicketDetails(action['id']); }), map(function (ticket) { return new TicketListModule.SuccessTicketDetails(ticket); }), catchError(function (err) { return of(new TicketListModule.ErrorLoadAction(err)); }));
        this.LoadTicketSearch$ = this.actions$
            .pipe(ofType(TicketListModule.ActionTypes.LOAD_SEARCH_TICKETS), exhaustMap(function (action) { return _this.ticketService.getSearchResults(action['keyword']); }), map(function (tickets) { return new TicketListModule.SuccessSearchTickets(tickets); }), catchError(function (err) { return of(new TicketListModule.ErrorLoadAction(err)); }));
        this.LoadTicketCategories$ = this.actions$
            .pipe(ofType(TicketListModule.ActionTypes.LOAD_TICKET_CATEGORIES), switchMap(function (action) { return _this.ticketService.getCategories(); }), map(function (categories) { return new TicketListModule.SuccessLoadingCategories(categories); }), catchError(function (err) { return of(new TicketListModule.ErrorLoadAction(err)); }));
        this.LoadCreateTicket$ = this.actions$
            .ofType(TicketListModule.ActionTypes.LOAD_CREATE_TICKET)
            .map(function (action) { return action; })
            .switchMap(function (payload) {
            return _this.ticketService.addTicket(payload)
                .map(function (data) {
                return new SuccessCreateTicket(data);
            });
            // .catch((error) => {
            //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
            // });
        });
        this.SuccessCreateTicket$ = this.actions$.pipe(ofType(TicketListModule.ActionTypes.SUCCESS_CREATE_TICKET), tap(function (data) {
            new TicketListModule.SuccessInitTickets(data['input']);
            _this.router.navigate(['ticket']);
        }));
        this.LoadAddFollowup$ = this.actions$
            .ofType(TicketListModule.ActionTypes.LOAD_ADD_FOLLOWUP)
            .map(function (action) { return action; })
            .switchMap(function (payload) {
            return _this.ticketService.addFollowup(payload)
                .map(function (data) {
                return new SuccessAddFollowup(data);
            });
            // .catch((error) => {
            //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
            // });
        });
        this.SuccessAddFollowup$ = this.actions$.pipe(ofType(TicketListModule.ActionTypes.SUCCESS_ADD_FOLLOWUP), tap(function (data) {
            new TicketListModule.SuccessAddFollowup(data['input']);
        }));
        this.LoadUpdateTicket$ = this.actions$
            .ofType(TicketListModule.ActionTypes.LOAD_UPDATE_TICKET)
            .map(function (action) { return action; })
            .switchMap(function (payload) {
            return _this.ticketService.updateTicket(payload)
                .map(function (data) {
                return new SuccessUpdateTicket(data);
            });
            // .catch((error) => {
            //   return of(new TicketListModule.ErrorLoadAction({ error: error.error.message }));
            // });
        });
        this.SuccessUpdateTicket$ = this.actions$.pipe(ofType(TicketListModule.ActionTypes.SUCCESS_UPDATE_TICKET), tap(function (data) {
            new TicketListModule.SuccessUpdateTicket(data);
        }));
        this.LoadUploadDocument$ = this.actions$
            .ofType(TicketListModule.ActionTypes.LOAD_UPLOAD_DOCUMENT)
            .map(function (action) { return action; })
            .flatMap(function (payload) {
            return _this.ticketService.addDocument(payload)
                .map(function (data) {
                return new SuccessUploadDocument(data);
            });
        });
        this.SuccessUploadDocument$ = this.actions$.pipe(ofType(TicketListModule.ActionTypes.SUCCESS_UPLOAD_DOCUMENT), tap(function (data) {
            new TicketListModule.SuccessUploadDocument(data['formData']);
        }));
        this.LoadLastTicket$ = this.actions$
            .ofType(TicketListModule.ActionTypes.LOAD_LAST_TICKET)
            .map(function (action) { return action; })
            .switchMap(function (payload) {
            return _this.ticketService.getLastTicket()
                .map(function (data) {
                return new SuccessLastTicket(data);
            });
        });
        this.SuccessLastTicket$ = this.actions$.pipe(ofType(TicketListModule.ActionTypes.SUCCESS_LAST_TICKET), tap(function (data) {
            new TicketListModule.SuccessLastTicket(data);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadTickets$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadTicketSearch$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadTicketCategories$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadCreateTicket$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "SuccessCreateTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadAddFollowup$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "SuccessAddFollowup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadUpdateTicket$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "SuccessUpdateTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadUploadDocument$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "SuccessUploadDocument$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "LoadLastTicket$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], TicketListEffects.prototype, "SuccessLastTicket$", void 0);
    return TicketListEffects;
}());
export { TicketListEffects };
