/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../search/search.component.ngfactory";
import * as i6 from "../search/search.component";
import * as i7 from "@ngrx/store";
import * as i8 from "../search/search.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./header.component";
import * as i11 from "../../../features/auth/auth.service";
import * as i12 from "angular2-toaster/src/toaster.service";
import * as i13 from "./header.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "header", [["class", "app-header navbar"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵm, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeExpended() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["alt", "Menu lat\u00E9ral"], ["class", "fas fa-bars"], ["title", "Menu lat\u00E9ral"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [["alt", "Bouton principal d'acc\u00E8s \u00E0 la page d'accueil"], ["class", "navbar-brand"], ["routerLink", "/"], ["title", "Home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "navbar-icon"], ["src", "favicon.ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "title-app"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Helpcenter "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-layout-search", [["class", "panel-search"]], null, null, null, i5.View_SearchComponent_0, i5.RenderType_SearchComponent)), i1.ɵdid(10, 114688, null, 0, i6.SearchComponent, [i7.Store, i8.SearchService, i3.ActivatedRoute, i7.ActionsSubject, i9.TranslateService, i3.Router], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 14, "ul", [["class", "nav navbar-nav right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "li", [["class", "item-flag"], ["value", "en"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.translate.use("en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["src", "../../../../assets/translation/en.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "li", [["class", "item-flag"], ["value", "fr"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.translate.use("fr") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["src", "../../../../assets/translation/fr.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "nav-link"], ["download", "Guide utilisateur"], ["href", "../../../assets/Guide_HelpCenter_2019.pdf"], ["target", "_blank"], ["title", "Guide utilisateur"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [["class", "nav-link"], ["target", "_blank"], ["title", "Mon compte"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "a", [["class", "nav-link"], ["id", "logout"], ["title", "D\u00E9connexion"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "i", [["class", "fas fa-power-off"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 5, 0, currVal_2); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.username; _ck(_v, 22, 0, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i10.HeaderComponent, [i11.AuthService, i3.Router, i12.ToasterService, i13.HeaderService, i7.Store, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i10.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
