import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  URL_API_LOGIN,
  URL_API_LOGOUT,
  URL_API_RESET,
  URL_API_SEND_EMAIL_RESET,
  URL_API_GET_USERNAME,
  URL_API_DISABLE_ENABLE_USER,
} from "../../core/UrlAPI";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  /**
   * Appelle l'api GLPI afin de logger l'utilisateur
   * @param login
   * @param password
   */
  signIn(login: string, password: string) {
    const credentials = window.btoa(
      unescape(encodeURIComponent(login + ":" + password))
    );
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    var attemptsArray = JSON.parse(localStorage.getItem("attempts") || "[]");
    const incrementAttempt = obj => obj.username === login && obj.attempts > 0;
    const maxAttempt = obj => obj.username === login && obj.attempts === 4;
    const disabled = obj => obj.username === login && obj.attempts > 4;
    const checkUsername = obj => obj.username === login;
    if (attemptsArray.length === 0 || !attemptsArray.some(checkUsername)) {
      attemptsArray.push({username: login, attempts: 1, enabled: false});
      localStorage.setItem("attempts", JSON.stringify(attemptsArray));
    } else if (attemptsArray.some(maxAttempt)) {
      attemptsArray.forEach((element, index) => {
        if(element.username === login) {
          attemptsArray[index].attempts = ++attemptsArray[index].attempts;
        }
    });
      localStorage.setItem("attempts", JSON.stringify(attemptsArray));
      return this.http.post(
        URL_API_DISABLE_ENABLE_USER,
        { username: login , is_active : 0},
        { headers: httpHeaders }
      );
    } else if (attemptsArray.some(disabled)) {
      return Observable.of({
        "ok": true,
        "disabled": true,
        "message": "Votre compte est désactivé suite à plusieurs tentatives de connexion infructueuses, pour le réactiver, veuillez cliquer sur Mot de passe oublié."
      });
    } else if (attemptsArray.some(incrementAttempt)) {
      attemptsArray.forEach((element, index) => {
        if(element.username === login) {
          attemptsArray[index].attempts = ++attemptsArray[index].attempts;
        }
    });
      localStorage.setItem("attempts", JSON.stringify(attemptsArray));
    }
    return this.http.post(
      URL_API_LOGIN,
      { credentials: credentials },
      { headers: httpHeaders }
    );
  }

  /**
   * Appelle l'api GLPI afin de déconnecter l'utilisateur
   */
  signOut() {
    const token = localStorage.getItem(environment.headerToken);
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Session-Token": token,
    });
    this.http.get(URL_API_LOGOUT, { headers: httpHeaders });
    localStorage.removeItem(environment.headerToken);
  }

  /**
   * Vérifie si un utilisateur est bien connecté
   */
  isAuth() {
    return localStorage.getItem(environment.headerToken) !== null;
  }

  sendEmailReset(payload) {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post(URL_API_SEND_EMAIL_RESET, payload, {
      headers: httpHeaders,
    });
  }

  validateReset(payload) {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post(URL_API_RESET, payload, { headers: httpHeaders });
  }

  sendGetUsername(payload) {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post(URL_API_GET_USERNAME, payload, {
      headers: httpHeaders,
    });
  }
  enableAccount(login: string) {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(
      URL_API_DISABLE_ENABLE_USER,
      { username: login , is_active : 1},
      { headers: httpHeaders }
    );
  }
}
