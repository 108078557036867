/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../../features/auth/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "angular2-toaster/src/toaster.service";
import * as i7 from "../header/header.service";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/common";
import * as i11 from "../sidebar/sidebar.component.ngfactory";
import * as i12 from "../sidebar/sidebar.component";
import * as i13 from "../sidebar/sidebar.service";
import * as i14 from "./layout-component.component";
var styles_LayoutComponentComponent = [i0.styles];
var RenderType_LayoutComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponentComponent, data: {} });
export { RenderType_LayoutComponentComponent as RenderType_LayoutComponentComponent };
export function View_LayoutComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.AuthService, i5.Router, i6.ToasterService, i7.HeaderService, i8.Store, i9.TranslateService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["id", "app-body"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i10.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "expended": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-sidebar", [["id", "sidebar"]], null, null, null, i11.View_SidebarComponent_0, i11.RenderType_SidebarComponent)), i1.ɵdid(6, 114688, null, 0, i12.SidebarComponent, [i8.Store, i13.SidebarService, i7.HeaderService, i5.Router], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _ck(_v, 4, 0, _co.expended); _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_LayoutComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-component", [], null, null, null, View_LayoutComponentComponent_0, RenderType_LayoutComponentComponent)), i1.ɵdid(1, 114688, null, 0, i14.LayoutComponentComponent, [i6.ToasterService, i7.HeaderService, i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentComponentNgFactory = i1.ɵccf("app-layout-component", i14.LayoutComponentComponent, View_LayoutComponentComponent_Host_0, {}, {}, []);
export { LayoutComponentComponentNgFactory as LayoutComponentComponentNgFactory };
